@import '../../../../styles/basics';

.bb-where-my-money-goes {
  @include bb-standalone-spacing-new();
  min-height: 300px;
  transition: all .3s;
  position: relative;
  background-image: url('/static/textures/concern-letters/concern-o.svg');
  background-position: -82px -104px;
  background-repeat: no-repeat;
  background-size: 330px auto, cover;
  padding: 40px 0;
  overflow: hidden;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  &:global(.behaviour-settings__top-spacing--no_spacing) {
    margin-top: 0;
  }

  &:global(.behaviour-settings__bottom-spacing--no_spacing) {
    margin-bottom: 0;
  }

  &__site-logo {
    margin-bottom: 27px;

    a {
      display: inline-block;

      // Disabling green hover color.
      &:hover path {
        fill: $white;
      }
    }
  }

  &__logo-image {
    height: 39px;
    width: auto;

  }

  &__strapline {
    width: 100%;
    max-width: 320px;
    margin-bottom: 40px;
  }

  &__heading {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__info-with-icons {
    display: flex;
    margin-bottom: 16px;
  }

  &__icons {
    position: relative;
    width: 112px;
  }

  &__heart-icon {
    position: absolute;
    left: -4px;
    top: 16px;
    width: 92px;
    height: auto;
    path {
      fill: $pink-800;
    }
  }

  &__hand-icon {
    width: 86px;
    position: relative;
    left: 0;
    top: 56px;
  }

  &__main-info {
    display: flex;
    flex-direction: column;
  }

  &__large-info {
    font-size: 60px;
    font-weight: bold;
    line-height: $line-height-small;
  }
  &__small-info {
    font-size: 32px;
    font-weight: bold;
  }

  &__description {
    font-size: 18px;
    line-height: $line-height-large;
    margin-bottom: 16px;

    strong {
      padding: 0 4px 4px 4px;
      background-color: $turquoise-100;
    }
  }

  &__toggle {
    position: relative;
    font-size: 16px;
    font-weight: bold;
    color: $black;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      color: $black;
    }
    &:after {
      content: ' ';
      position: absolute;
      right: -20px;
      top: 5px;
      width: 9px;
      height: 9px;
      display: block;
      border-bottom: 2px solid $black;
      border-right: 2px solid $black;
      transform: rotate(45deg);
    }
    &--toggled:after {
      transform: rotate(-135deg);
      top: 10px;
    }
  }

  &--opened {
    min-height: 540px;

    .bb-where-my-money-goes-panel {
      max-height: 1000px;
      z-index: 1;
      position: relative;
    }
  }

  @include media-breakpoint-up(sm) {
    background-position: -85px -150px, calc(100% + 195px) -268px;
    background-image:
            url('/static/textures/concern-letters/concern-o.svg'),
            url('/static/textures/concern-letters/concern-c.svg');
    background-size: 370px auto, 294px auto, cover;
  }

  @include media-breakpoint-up(md) {
    &__logo-and-strapline-container {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__container {
      position: relative;
    }

    &__info {
      margin-left: 344px;
      max-width: 465px;
    }

    &__description {
      font-size: 16px;
    }
  }

  @include media-breakpoint-up(lg) {
    background-position: -55px -160px, 100% -281px;
  }

  @include media-breakpoint-up(xl) {
    background-image: url('/static/textures/concern-letters/concern-o.svg');
    background-position: -179px -244px;
    background-size: 660px auto, cover;
    min-height: 480px;

    &:global(.behaviour-settings__bottom-spacing--no_spacing) {
      min-height: 496px;
    }

    &__logo-and-strapline-container {
      top: 20px;
      min-width: 444px;
      margin-bottom: 120px;
    }

    &__info {
      margin-left: 492px;
      min-height: 309px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    &__site-logo {
      margin-bottom: 45px;
    }

    &__strapline {
      max-width: 444px;
    }

    &__icons {
      width: 52px;
    }

    &__hand-icon {
      width: 40px;
      top: 32px;
    }

    &__heart-icon {
      max-width: 42px;
      top: 15px;
      left: -1px;
    }

    &__main-info {
      flex-direction: row;
      gap: 15px;
      align-items: flex-end;
    }

    &__small-info {
      font-size: 28px;
    }

    &__description {
      font-size: 18px;
      margin-bottom: 20px;
    }

    &__toggle {
      font-size: 18px;
    }
  }

  @media (min-width: 1400px) {
    background-position: -179px -244px, calc(100% + 280px) -468px;
    background-image:
            url('/static/textures/concern-letters/concern-o.svg'),
            url('/static/textures/concern-letters/concern-c.svg');
    background-size: 660px 707px, 542px 604px, cover;

    &__info {
      margin-left: 516px;
    }
  }

  @media (min-width: 1750px) {
    background-position: -179px -244px, calc(100% - 127px) -468px, calc(100% + 365px) -641px;
    background-image: url('/static/textures/concern-letters/concern-o.svg'),
    url('/static/textures/concern-letters/concern-c.svg'),
    url('/static/textures/concern-letters/concern-e.svg');
    background-size: 660px 707px, 542px 604px, 620px 809px, cover;
  }
}

.bb-where-my-money-goes-panel {
  width: 100%;
  overflow: hidden;
  transition: all .3s;
  max-height: 0;

  &__items {
    margin-top: 40px;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__item {
    margin: 0;
    padding: 0;
    &::before {
      content: none;
    }

    h4 {
      display: flex;
      align-items: center;
      color: $green-800;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
      svg {
        margin-right: 8px;
        max-height: 24px;
        max-width: 24px;

        path {
          fill: $green-800;
        }
      }
    }
  }

  &__large-text {
    font-size: 28px;
    font-weight: bold;
  }
  &__small-text {
    font-size: 18px;
    font-weight: bold;
    margin-left: 8px;
  }
  &__description {
    font-size: 16px;
    margin-bottom: 0;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &__learn-more {
    margin: 0 0 16px 0;
    width: 100%;
    display: block;
    color: $turquoise-1000;
    border: 1px solid;
    border-radius: 25px;
    cursor: pointer;
    padding: 8px 28px;
    font-weight: 600;
    text-align: center;

    &:hover {
      text-decoration: none;
    }
  }

  @include media-breakpoint-up(md) {
    &__buttons {
      margin-bottom: 5px;
    }
  }

  @include media-breakpoint-up(lg) {
    margin: 8px auto 0;
    &__container {
      background-color: $white;
      border: 1px solid $slate-200;
      box-shadow: 0 1px 6px rgba(0,0,0,0.2);
      border-radius: 8px;
      padding: 32px 40px 40px;
    }
    &__items {
      flex-direction: row;
      gap: 32px;
      margin-top: 0;
    }

    &__item {
      flex: 1 1 0;
    }

    &__buttons {
      max-width: 670px;
      margin: 56px auto 0;

      .bb-where-my-money-goes__toggle {
        display: none;
      }
    }
    &__learn-more {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(xl) {
    &__container {
      margin: 0 66px 5px;
    }
  }
}

@keyframes heart-grow-mobile {
  0% {
    transform: scale(1);
  }
  10%, 20%, 30%, 70% {
    transform: scale(1.5) translateX(-7px);
  }
  15%, 25% {
    transform: scale(1.4) translateX(-7px) translateY(1px);
  }

  75%, 85%, 95%, 100% {
    transform: scale(1);
  }
  80%, 90% {
    transform: scale(1.1) translateY(-1px);
  }
}

@keyframes heart-grow-desktop {
  0% {
    transform: scale(1);
  }
  10%, 20%, 30%, 70% {
    transform: scale(2) translateX(-5px);
  }
  15%, 25% {
    transform: scale(1.9) translateX(-5px);
  }

  75%, 85%, 95%, 100% {
    transform: scale(1);
  }
  80%, 90% {
    transform: scale(1.1);
  }
}

.animation-mobile,
.animation-desktop {
  animation: heart-grow-mobile 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.animation-desktop {
  animation-name: heart-grow-desktop;
}


